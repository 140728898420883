@import-normalize; /* bring in normalize.css styles */

/* font-family: 'Abel', sans-serif; */
/* font-family: 'Ubuntu', sans-serif; */

:root{
  --background-color:#F5F5F5;
  --primary-color: #242424;
  --light-color: #fff;
  --grey-color: #F1F1F1;
  --accent-color: #FDC70D;
  --button-color: #FDC705;
  --animation-cubic:cubic-bezier(0.4, 0, 0.2, 1);
  --cubic:cubic-bezier(.68,0,.63,.58);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--primary-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

li{
  list-style: none;
}
ol{
  padding-left: 20px;
}
ol>li{
  list-style: auto;
  margin-bottom: 8px;
}
a{
  text-decoration: none;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
}

ul,h1,h2,p,h3, h4,h5{
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: var(--primary-color);
}
img{
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  color: var(--primary-color);
  border: none;
  background: none;
}
section{
  background-color: var(--background-color);
}


.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.my-slide {
  opacity: 0.5; 
  transition: opacity 0.3s ease-in-out; 
}

  .swiper-slide-next, .swiper-slide-prev, .swiper-slide-active {
    opacity: 1; 
  }

.unactive {
  color: var(--grey-color);
  opacity: 0.5;
  transition: color 250ms, opacity 250ms;
}

.active {
  color: var(--accent-color);
  opacity: 1;
  transition: color 250ms, opacity 250ms;
}
.unactive:hover .active {
  color: var(--grey-color);
  opacity: 0.5;
}
.unactive:hover,
.unactive:focus {
  color: var(--accent-color);
  opacity: 1;
}







